import React from 'react';

import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';

import * as styles from './404.module.css';
import ContentfulTranslationText from '../components/atoms/ContentfulTranslationText/ContentfulTranslationText';

const PageNotFound = () => {
  return (
    <Layout>
      <Seo title="Page Not Found" />
      <div className={styles.layout}>
        <Container>
          <h1 className={styles.title}><ContentfulTranslationText keyName="oops">Oops</ContentfulTranslationText>!</h1>
          <p className={styles.description}>
            <ContentfulTranslationText keyName="pageNotFoundContent">We can't seem to find the page you're looking for.</ContentfulTranslationText>
          </p>
          <span className={styles.errorCode}><ContentfulTranslationText keyName="errorCode">Error code</ContentfulTranslationText>: 404</span>
        </Container>
      </div>
    </Layout>
  );
};

export default PageNotFound;
